import Header from "components/Header";
import Loader from "components/Loader";
import Footer from "components/Footer";

import { portfolioPage } from "frontend-structure";

const Portfolio = () => {
	return (
		<>
			<Loader />
			<Header />

			<div className="our-portfolio portfolio-full-width-grid">
				<div className="d-lg-flex">
					<div className="gallery-sidebar">
						<div className="filter-list">
							<h5 className="title">Categorie</h5>
							<div id="js-filters-sidebar" className="cbp-l-filters-alignCenter filter-menu">
								<div data-filter="*" className="cbp-filter-item cbp-filter-item-active">
									All <div className="cbp-filter-counter">6</div>
								</div>
								<div data-filter=".web" className="cbp-filter-item">
									Web Design <div className="cbp-filter-counter">2</div>
								</div>
								<div data-filter=".mockup" className="cbp-filter-item">
									Mockup <div className="cbp-filter-counter">2</div>
								</div>
								<div data-filter=".print" className="cbp-filter-item">
									Print Design <div className="cbp-filter-counter">2</div>
								</div>
								<div data-filter=".logo" className="cbp-filter-item">
									Logo <div className="cbp-filter-counter">2</div>
								</div>
								<div data-filter=".app" className="cbp-filter-item">
									Application <div className="cbp-filter-counter">2</div>
								</div>
								<div data-filter=".branding" className="cbp-filter-item">
									Branding <div className="cbp-filter-counter">2</div>
								</div>
								<div data-filter=".illus" className="cbp-filter-item">
									Illusutration <div className="cbp-filter-counter">2</div>
								</div>
							</div>
						</div>

						<div className="share-option">
							<h5 className="title">Share it. </h5>
							<ul>
								<li>
									<a href="#">
										<i className="fa fa-facebook" aria-hidden="true"></i>
									</a>
								</li>
								<li>
									<a href="#">
										<i className="fa fa-twitter" aria-hidden="true"></i>
									</a>
								</li>
								<li>
									<a href="#">
										<i className="fa fa-linkedin" aria-hidden="true"></i>
									</a>
								</li>
								<li>
									<a href="#">
										<i className="fa fa-dribbble" aria-hidden="true"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="img-content-wrapper">
						<div className="theme-title-one mb-60">
							<div className="upper-title mb-5">Portfolio</div>
							<h2 className="main-title">
								Porfolio <i>Gallery</i>
							</h2>
						</div>
						<div
							id="sidebar-cube-gallery"
							className="cbp cube-port cbp-caption-active cbp-caption-overlayBottomAlong cbp-ready"
							sx="height: 1831px;">
							<div className="cbp-wrapper-outer">
								<div className="cbp-wrapper">
									<div
										className="cbp-item print branding"
										sx="width: 589px; left: 0px; top: 0px;">
										<div className="cbp-item-wrapper">
											<a
												href="images/gallery/33.png"
												className="cbp-caption cbp-lightbox"
												data-title="Ullamcorper vestibulum lectus lorem ultrices convallis ut">
												<div className="">
													<img src="images/gallery/33.png" alt="" />
												</div>
											</a>
											<div className="text">
												<h3>Minimal Iphone</h3>
												<p>Mockup</p>
												<a href="portfolio-details-v2.html" className="read-more">
													+
												</a>
											</div>
										</div>
									</div>
									<div className="cbp-item web logo" sx="width: 589px; left: 649px; top: 0px;">
										<div className="cbp-item-wrapper">
											<a
												href="images/gallery/34.png"
												className="cbp-caption cbp-lightbox"
												data-title="Ullamcorper vestibulum lectus lorem ultrices convallis ut">
												<div className="">
													<img src="images/gallery/34.png" alt="" />
												</div>
											</a>
											<div className="text">
												<h3>Minimal Iphone</h3>
												<p>Mockup</p>
												<a href="portfolio-details-v2.html" className="read-more">
													+
												</a>
											</div>
										</div>
									</div>
									<div className="cbp-item mockup app" sx="width: 589px; left: 0px; top: 627px;">
										<div className="cbp-item-wrapper">
											<a
												href="images/gallery/35.png"
												className="cbp-caption cbp-lightbox"
												data-title="Ullamcorper vestibulum lectus lorem ultrices convallis ut">
												<div className="">
													<img src="images/gallery/35.png" alt="" />
												</div>
											</a>
											<div className="text">
												<h3>Minimal Iphone</h3>
												<p>Mockup</p>
												<a href="portfolio-details-v2.html" className="read-more">
													+
												</a>
											</div>
										</div>
									</div>
									<div className="cbp-item web illus" sx="width: 589px; left: 649px; top: 627px;">
										<div className="cbp-item-wrapper">
											<a
												href="images/gallery/36.png"
												className="cbp-caption cbp-lightbox"
												data-title="Ullamcorper vestibulum lectus lorem ultrices convallis ut">
												<div className="">
													<img src="images/gallery/36.png" alt="" />
												</div>
											</a>
											<div className="text">
												<h3>Minimal Iphone</h3>
												<p>Mockup</p>
												<a href="portfolio-details-v2.html" className="read-more">
													+
												</a>
											</div>
										</div>
									</div>
									<div
										className="cbp-item mockup logo branding"
										sx="width: 589px; left: 0px; top: 1254px;">
										<div className="cbp-item-wrapper">
											<a
												href="images/gallery/37.png"
												className="cbp-caption cbp-lightbox"
												data-title="Ullamcorper vestibulum lectus lorem ultrices convallis ut">
												<div className="">
													<img src="images/gallery/37.png" alt="" />
												</div>
											</a>
											<div className="text">
												<h3>Minimal Iphone</h3>
												<p>Mockup</p>
												<a href="portfolio-details-v2.html" className="read-more">
													+
												</a>
											</div>
										</div>
									</div>
									<div
										className="cbp-item print app illus"
										sx="width: 589px; left: 649px; top: 1254px;">
										<div className="cbp-item-wrapper">
											<a
												href="images/gallery/38.png"
												className="cbp-caption cbp-lightbox"
												data-title="Ullamcorper vestibulum lectus lorem ultrices convallis ut">
												<div className="">
													<img src="images/gallery/38.png" alt="" />
												</div>
											</a>
											<div className="text">
												<h3>Minimal Iphone</h3>
												<p>Mockup</p>
												<a href="portfolio-details-v2.html" className="read-more">
													+
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="theme-pagination-one pt-65">
							<ul>
								<li className="active">
									<a href="#">1</a>
								</li>
								<li>
									<a href="#">2</a>
								</li>
								<li>
									<a href="#">3</a>
								</li>
								<li>
									<a href="#">
										<i className="flaticon-next-1"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};

export default Portfolio;
