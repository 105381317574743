import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "components/Header";
import Loader from "components/Loader";
import Footer from "components/Footer";

import AboutImg from "images/about.jpg";

import { about } from "frontend-structure";

const About = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<>
			<div className="main-page-wrapper">
				<Loader />

				<Header />

				<div className="banner-large-text pt-250 pb-350 pos-r md-pt-200 md-pb-150">
					<div className="shape-wrapper">
						<img src="images/shape/13.svg" alt="" className="shape-two img-shape" />
						<img src="images/shape/5.svg" alt="" className="shape-three img-shape" />
						<img src="images/shape/4.svg" alt="" className="shape-four img-shape" />
						<img src="images/shape/47.svg" alt="" className="shape-five img-shape" />
						<img src="images/shape/4.svg" alt="" className="shape-six img-shape" />
						<img src="images/shape/48.svg" alt="" className="shape-seven img-shape" />
						<img src="images/shape/9.svg" alt="" className="shape-eight img-shape" />
						<img src="images/shape/49.svg" alt="" className="shape-nine img-shape" />
					</div>
					<div className="container">
						<div className="inner-wrapper pos-r">
							<div className="shape-wrapper">
								<div className="img-shape shape-one">
									<img src={AboutImg} alt="" />
								</div>
							</div>
							<h2 className="title">
								<span className="pos-r">{about.hero}</span> <br /> {about.hero2}.
							</h2>
							<div className="row">
								<div className="col-xl-5 col-lg-6 col-md-7">
									<p className="sub-heading pb-25">{about.description}</p>
									<p>
										We put your ideas and thus your wishes in the form of a unique web project that
										inspires you and you customers. We’ve team to solve your problems.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="about-us-text bg-color pos-r pb-200 md-pt-50 md-pb-50">
					<div className="container">
						<div className="row">
							<div className="col-lg-4">
								<div className="theme-title-one">
									<div className="upper-title mb-5">About us</div>
									<h2 className="main-title">Digital Agency Since 1980.</h2>
								</div>
							</div>
							<div className="col-lg-4 about-text-one">
								<h4 className="pb-20 md-pt-50">Our Goal </h4>
								<p>
									Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying
									out print, graphic or web designs.The attributed some to an unknown typesetter
									in the 15th century eu who is thought to have scrambled parts of Cicero'sDe
									lorem.
								</p>
								<p>Malorum for use in a type specimen book. Ionsectetur adipiscing elit.</p>
							</div>
							<div className="col-lg-4 about-text-one">
								<h4 className="pb-20 md-pt-50">Our Vision </h4>
								<p>
									Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying
									out printed graphic or web designs in 1the 5th century who is thought scrambled
									with parts of Cicero's De. Lorem ipsum, or lipsum as
								</p>
								<h6 className="mt-30 mb-20">Derek Ramsey</h6>
								<img src="images/home/sign2.png" alt="" />
							</div>
						</div>

						<div className="mt-170 md-mt-80">
							<div className="row">
								<div className="col-lg-4 col-md-6">
									<div className="feature-block-two text-center" data-aos="fade-up">
										<img src="images/icon/icon11.svg" alt="" className="icon" />
										<h5 className="pt-25 pb-35">Blazing Performance</h5>
										<p>Praesent porttitor nunc vit lorem lacus vehicula necu.</p>
									</div>
								</div>
								<div className="col-lg-4 col-md-6">
									<div
										className="feature-block-two text-center"
										data-aos="fade-up"
										data-aos-delay="100">
										<img src="images/icon/icon12.svg" alt="" className="icon" />
										<h5 className="pt-25 pb-35">Unlimited Layouts</h5>
										<p>Praesent porttitor nunc vit lorem lacus vehicula necu.</p>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 m-auto">
									<div
										className="feature-block-two text-center"
										data-aos="fade-up"
										data-aos-delay="200">
										<img src="images/icon/icon13.svg" alt="" className="icon" />
										<h5 className="pt-25 pb-35">Super Responsive</h5>
										<p>Praesent porttitor nunc vit lorem lacus vehicula necu.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="team-standard our-team pt-150 pb-200 sm-pb-90">
					<div className="container">
						<div className="theme-title-one text-center mb-80">
							<div className="upper-title mb-5">Team</div>
							<h2 className="main-title">Our Qulified Agents</h2>
						</div>
						<div className="row">
							<div className="col-lg-4 col-md-6">
								<div className="single-team-member">
									<div className="wrapper pos-r">
										<div className="img-box">
											<img src="images/team/1.jpg" alt="" />
										</div>
										<div className="info-meta">
											<h6 className="name">Hunter Chapman</h6>
											<span>Co-Founder</span>
										</div>
									</div>
									<div className="hover-content">
										<ul>
											<li>
												<a href="#">
													<i className="fa fa-facebook" aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fa fa-twitter" aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fa fa-linkedin" aria-hidden="true"></i>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div className="single-team-member">
									<div className="wrapper pos-r">
										<div className="img-box">
											<img src="images/team/2.jpg" alt="" />
										</div>
										<div className="info-meta">
											<h6 className="name">Richard Lowe</h6>
											<span>UI/UX designer</span>
										</div>
									</div>
									<div className="hover-content">
										<ul>
											<li>
												<a href="#">
													<i className="fa fa-facebook" aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fa fa-twitter" aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fa fa-linkedin" aria-hidden="true"></i>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div className="single-team-member">
									<div className="wrapper pos-r">
										<div className="img-box">
											<img src="images/team/3.jpg" alt="" />
										</div>
										<div className="info-meta">
											<h6 className="name">Johnny Yates</h6>
											<span>Project Manager</span>
										</div>
									</div>
									<div className="hover-content">
										<ul>
											<li>
												<a href="#">
													<i className="fa fa-facebook" aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fa fa-twitter" aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fa fa-linkedin" aria-hidden="true"></i>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div className="single-team-member">
									<div className="wrapper pos-r">
										<div className="img-box">
											<img src="images/team/5.jpg" alt="" />
										</div>
										<div className="info-meta">
											<h6 className="name">Jerry Buchanan</h6>
											<span>Developer</span>
										</div>
									</div>
									<div className="hover-content">
										<ul>
											<li>
												<a href="#">
													<i className="fa fa-facebook" aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fa fa-twitter" aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fa fa-linkedin" aria-hidden="true"></i>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div className="single-team-member">
									<div className="wrapper pos-r">
										<div className="img-box">
											<img src="images/team/6.jpg" alt="" />
										</div>
										<div className="info-meta">
											<h6 className="name">Joshua Parsons</h6>
											<span>Marketing Expert</span>
										</div>
									</div>
									<div className="hover-content">
										<ul>
											<li>
												<a href="#">
													<i className="fa fa-facebook" aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fa fa-twitter" aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fa fa-linkedin" aria-hidden="true"></i>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div className="single-team-member">
									<div className="wrapper pos-r">
										<div className="img-box">
											<img src="images/team/4.jpg" alt="" />
										</div>
										<div className="info-meta">
											<h6 className="name">Nannie Henry</h6>
											<span>Back-end developer</span>
										</div>
									</div>
									<div className="hover-content">
										<ul>
											<li>
												<a href="#">
													<i className="fa fa-facebook" aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fa fa-twitter" aria-hidden="true"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fa fa-linkedin" aria-hidden="true"></i>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="testimonial-classNameic-v2 mt-150 mb-150 md-mb-80 sm-mt-20">
					<div className="main-bg-wrapper pos-r">
						<div className="quote">
							<span data-aos="fade-up">Quote</span>
						</div>
						<div className="overlay pos-r">
							<div className="main-slider-wrapper">
								<div className="testimonial-slider-cl2">
									<div className="item">
										<svg className="icon">
											<path
												fill-rule="evenodd"
												fill="rgb(255, 255, 255)"
												d="M-0.000,0.052 L-0.000,30.052 L15.000,15.053 L15.000,0.052 L-0.000,0.052 Z"
											/>
											<path
												fill-rule="evenodd"
												fill="rgb(255, 255, 255)"
												d="M25.000,0.052 L25.000,30.052 L40.000,15.053 L40.000,0.052 L25.000,0.052 Z"
											/>
										</svg>
										<p>
											“Excepteur sint occaecat cupida non at proident, sunt culp officia deserun moll
											anim est laborum. Sed perspi!
										</p>
										<div className="d-flex align-items-center">
											<img src="images/home/2.jpg" alt="" />
											<div className="name">
												<h6>Rashed Ka.</h6>
												<span>Berlin</span>
											</div>
										</div>
									</div>
									<div className="item">
										<svg className="icon">
											<path
												fill-rule="evenodd"
												fill="rgb(255, 255, 255)"
												d="M-0.000,0.052 L-0.000,30.052 L15.000,15.053 L15.000,0.052 L-0.000,0.052 Z"
											/>
											<path
												fill-rule="evenodd"
												fill="rgb(255, 255, 255)"
												d="M25.000,0.052 L25.000,30.052 L40.000,15.053 L40.000,0.052 L25.000,0.052 Z"
											/>
										</svg>
										<p>
											“Excepteur sint occaecat cupida non at proident, sunt culp officia deserun moll
											anim est laborum. Sed perspi!
										</p>
										<div className="d-flex align-items-center">
											<img src="images/home/3.jpg" alt="" />
											<div className="name">
												<h6>Zubayer Hasan</h6>
												<span>Berlin</span>
											</div>
										</div>
									</div>
									<div className="item">
										<svg className="icon">
											<path
												fill-rule="evenodd"
												fill="rgb(255, 255, 255)"
												d="M-0.000,0.052 L-0.000,30.052 L15.000,15.053 L15.000,0.052 L-0.000,0.052 Z"
											/>
											<path
												fill-rule="evenodd"
												fill="rgb(255, 255, 255)"
												d="M25.000,0.052 L25.000,30.052 L40.000,15.053 L40.000,0.052 L25.000,0.052 Z"
											/>
										</svg>
										<p>
											“Excepteur sint occaecat cupida non at proident, sunt culp officia deserun moll
											anim est laborum. Sed perspi!
										</p>
										<div className="d-flex align-items-center">
											<img src="images/home/4.jpg" alt="" />
											<div className="name">
												<h6>Foqrul Munna</h6>
												<span>Berlin</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="op-partner-section-one pt-80 pb-110 sm-pb-60">
					<div className="container">
						<div className="row">
							<div className="col-lg-3 col-md-4 col-6">
								<div className="img-box bx-a">
									<a href="#">
										<img src="images/logo/p-1.png" alt="" />
									</a>
								</div>
							</div>
							<div className="col-lg-2 col-md-4 col-6">
								<div className="img-box bx-b">
									<a href="#">
										<img src="images/logo/p-2.png" alt="" />
									</a>
								</div>
							</div>
							<div className="col-lg-3 col-md-4 col-6">
								<div className="img-box bx-c">
									<a href="#">
										<img src="images/logo/p-3.png" alt="" />
									</a>
								</div>
							</div>
							<div className="col-xl-2 col-lg-3 col-md-4 col-6">
								<div className="img-box bx-d">
									<a href="#">
										<img src="images/logo/p-4.png" alt="" />
									</a>
								</div>
							</div>
							<div className="col-xl-2 col-lg-3 col-md-4 col-6">
								<div className="img-box bx-e">
									<a href="#">
										<img src="images/logo/p-5.png" alt="" />
									</a>
								</div>
							</div>
							<div className="col-xl-4 col-lg-2 col-md-4 col-6">
								<div className="img-box bx-f">
									<a href="#">
										<img src="images/logo/p-6.png" alt="" />
									</a>
								</div>
							</div>
							<div className="col-xl-2 col-lg-3 col-md-4 col-6">
								<div className="img-box bx-g">
									<a href="#">
										<img src="images/logo/p-7.png" alt="" />
									</a>
								</div>
							</div>
							<div className="col-xl-3 col-lg-3 col-md-4 col-6">
								<div className="img-box bx-h">
									<a href="#">
										<img src="images/logo/p-8.png" alt="" />
									</a>
								</div>
							</div>
							<div className="col-xl-3 col-lg-11 col-md-4 col-6">
								<div className="img-box bx-i">
									<a href="#">
										<img src="images/logo/p-9.png" alt="" />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="newsletter-section newsletter-two pos-r mt-45">
					<div className="shape-wrapper">
						<svg className="img-shape shape-one">
							<path
								fill-rule="evenodd"
								fill="rgb(255, 205, 111)"
								d="M8.000,15.999 C12.418,15.999 16.000,12.417 16.000,7.999 C16.000,3.581 12.418,-0.001 8.000,-0.001 C3.582,-0.001 -0.000,3.581 -0.000,7.999 C-0.000,12.417 3.582,15.999 8.000,15.999 Z"
							/>
						</svg>
						<svg className="img-shape shape-two">
							<path
								fill-rule="evenodd"
								fill="rgb(206, 124, 255)"
								d="M5.500,10.999 C8.537,10.999 11.000,8.537 11.000,5.499 C11.000,2.462 8.537,-0.001 5.500,-0.001 C2.462,-0.001 -0.000,2.462 -0.000,5.499 C-0.000,8.537 2.462,10.999 5.500,10.999 Z"
							/>
						</svg>
					</div>
					<div className="container">
						<div className="main-wrapper">
							<div className="theme-title-one text-center pb-55">
								<h2 className="main-title">
									Subscribe Newslatter & get
									<br /> <i>Company News.</i>
								</h2>
							</div>
							<form className="ajax-sub-form" method="post">
								<input type="email" placeholder="Enter your email" id="subscription-email" />
								<button className="button-rose">
									<i className="flaticon-next"></i>
								</button>
								<label className="subscription-label" for="subscription-email"></label>
							</form>
						</div>
					</div>
				</div>

				<Footer />
			</div>
		</>
	);
};

export default About;
