import { Link, useNavigate } from "react-router-dom";

import Footer from "components/Footer";
import Header from "components/Header";
import Loader from "components/Loader";

const Error = () => {
	return (
		<>
			<Loader />
			<Header />

			<div className="error-page">
				<div>
					<h2>Pagina non trovata</h2>
					<p>
						Sono spiacente <br />
						non ho trovato il contenuto che cercavi.
					</p>
					<Link to="/home" className="theme-btn solid-button-one button-rose">
						Torna alla home
					</Link>
				</div>
			</div>

			<Footer />
		</>
	);
};

export default Error;
