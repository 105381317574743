import React from "react";

import Loader from "components/Loader";
import Header from "components/Header";
import Footer from "components/Footer";

const Contact = () => {
	return (
		<div className="main-page-wrapper">
			<Loader />
			<Header />

			<div
				className="inner-banner pos-r banner-bg bg-style-two"
				sx="background-image: url(images/blog/bg2.jpg);">
				<div className="opacity">
					<div className="container">
						<p>Contact</p>
						<h2>Contact us</h2>
					</div>
				</div>
			</div>

			<div className="contact-us-section pt-160 pb-180 md-pt-120 md-pb-140">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 order-lg-last">
							<div className="contact-info md-mb-60">
								<div className="theme-title-one">
									<h2 className="main-title">
										Don’t Hesitate to contact with us for any <i>kind of information</i>
									</h2>
								</div>
								<p className="pt-35 pb-30">Call us for imidiate support to this number</p>
								<a href="#" className="call">
									+088-130-629-8615
								</a>
								<ul className="social-icon-two">
									<li>
										<a href="#" sx="background: #3b5998;">
											<i className="fa fa-facebook" aria-hidden="true"></i>
										</a>
									</li>
									<li>
										<a href="#" sx="background: #26a6d1;">
											<i className="fa fa-twitter" aria-hidden="true"></i>
										</a>
									</li>
									<li>
										<a href="#" sx="background: #007ab9;">
											<i className="fa fa-linkedin" aria-hidden="true"></i>
										</a>
									</li>
									<li>
										<a href="#" sx="background: #cb2027;">
											<i className="fa fa-pinterest" aria-hidden="true"></i>
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-6 order-lg-first">
							<div className="contact-form">
								<form
									className="form theme-form-style-two"
									id="contact-form"
									action="inc/contact.php"
									data-toggle="validator">
									<div className="messages"></div>
									<div className="controls">
										<div className="form-group">
											<input
												id="form_email"
												type="email"
												name="email"
												placeholder="Email Address*"
												required="required"
												data-error="Valid email is required."
											/>
											<div className="help-block with-errors"></div>
										</div>

										<div className="form-group">
											<input
												id="form_sub"
												type="text"
												name="sub"
												placeholder="Subject*"
												required="required"
												data-error="Subject is required."
											/>
											<div className="help-block with-errors"></div>
										</div>

										<div className="form-group">
											<textarea
												id="form_message"
												name="message"
												className="form_message"
												placeholder="Your Message*"
												required="required"
												data-error="Please,leave us a message."></textarea>
											<div className="help-block with-errors"></div>
										</div>
										<button className="theme-btn solid-button-one button-rose radius3">
											Send Message
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="google-map-two">
				<div className="map-canvas"></div>
			</div>

			<Footer />

			<button className="scroll-top tran3s">
				<i className="fa fa-angle-up" aria-hidden="true"></i>
			</button>
		</div>
	);
};

export default Contact;
