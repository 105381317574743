import { hero } from "frontend-structure";

const Hero2 = () => {
	return (
		<div className="rogan-hero-section rogan-hero-five pt-300 pb-200 md-pt-200 md-pb-100 pos-r">
			<div className="shape-wrapper">
				<div className="d-text">PB</div>
			</div>
			<div className="container">
				<div className="main-wrapper pos-r">
					<div
						className="banner-upper-heading wow fadeInDown  animated"
						sx="visibility: visible; animation-name: fadeInDown;">
						{hero.name}
					</div>
					<h1
						className="banner-main-title pt-15 pb-45 md-pt-10 md-pb-30 wow fadeInUp  animated"
						data-wow-delay="0.4s"
						sx="visibility: visible; animation-delay: 0.4s; animation-name: fadeInUp;">
						{hero.row1} <br /> <span>{hero.row2}</span>
					</h1>
					<p
						className="banner-sub-title pb-45 md-pb-30 wow fadeInUp  animated"
						data-wow-delay="0.9s"
						sx="visibility: visible; animation-delay: 0.9s; animation-name: fadeInUp;">
						{hero.text}
					</p>
					<a
						href="about-standard.html"
						className="theme-btn line-button-two p-green wow fadeInLeft  animated"
						data-wow-delay="1.5s"
						sx="visibility: visible; animation-delay: 1.5s; animation-name: fadeInLeft;">
						Scopri di più
						<i className="fa fa-angle-right icon-right" aria-hidden="true"></i>
					</a>
					<a
						data-fancybox=""
						href="https://www.youtube.com/embed/vVXAXDvgPiQ"
						className="fancybox video-button wow fadeInRight  animated"
						data-wow-delay="1.5s"
						sx="visibility: visible; animation-delay: 1.5s; animation-name: fadeInRight;">
						<i className="flaticon-play-button"></i>
					</a>
				</div>
			</div>
		</div>
	);
};

export default Hero2;
