// sweetalert
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const swal = withReactContent(Swal);

const handleError = (response, descriptionError) => {
	const message = `Errore: ${response.statusText}`;

	swalToast({
		icon: "error",
		title: `Attenzione! Operazione non andata a buon fine!`,
		text: `${message}`,
		footer: descriptionError,
		position: "top",
		showConfirmButton: true,
		timer: 12000,
	});
};

const swalToast = (params = {}) => {
	swal.fire({
		...params,
		toast: true,
		timerProgressBar: true,
		didOpen: (toast) => {
			toast.addEventListener("mouseenter", Swal.stopTimer);
			toast.addEventListener("mouseleave", Swal.resumeTimer);
		},
	});
};

//formattazione della data (es. 1° Febbraio 2023 => 01/02/2023)
/**
 *
 * @param {String} unformattedDate
 * @returns {Date}
 */
const getDate = (unformattedDate) => {
	return new Date(unformattedDate).toLocaleDateString("it-IT", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
	});
};

//data la stringa e una lunghezza massima la stringa viene troncata e se sfora la lunghezza massima vengono inseriti "..." in coda alla stringa
/**
 * @param {String} text
 * @param {Int} maxLength
 * @returns {String}
 */
const formatTextWithSubstring = (text, maxLength) => {
	return `${text.substring(0, maxLength)}${text.length > maxLength ? "..." : ""}`;
};

export { handleError, getDate, formatTextWithSubstring, swalToast };
