import { initializeApp } from "firebase/app";
import { handleError } from "./utilities";

import {
	GoogleAuthProvider,
	getAuth,
	signInWithPopup,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	sendPasswordResetEmail,
	signOut,
} from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyCmfmpM0q7nrlmdNxB3y0WD1HfAgkV39tY",
	authDomain: "paolobassini-1edba.firebaseapp.com",
	projectId: "paolobassini-1edba",
	storageBucket: "paolobassini-1edba.appspot.com",
	messagingSenderId: "1076170942112",
	appId: "1:1076170942112:web:cbb4c6e6a612cc75d38bc5",
	measurementId: "G-HL05T84153",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
	try {
		await signInWithPopup(auth, googleProvider);
	} catch (err) {
		console.error(err);
		// alert(err.message);
		handleError(err.message);
	}
};

const logInWithEmailAndPassword = async (email, password) => {
	try {
		await signInWithEmailAndPassword(auth, email, password);
		return true;
	} catch (err) {
		return err;
	}
};

const registerWithEmailAndPassword = async (email, password) => {
	try {
		await createUserWithEmailAndPassword(auth, email, password);
	} catch (err) {
		return err;
	}
};

const sendPasswordReset = async (email) => {
	try {
		if (email) {
			await sendPasswordResetEmail(auth, email);
		}
	} catch (err) {
		console.error(err);
		// alert(err.message);
		handleError(err.message);
	}
};

const logout = () => {
	signOut(auth);
};

export {
	auth,
	signInWithGoogle,
	logInWithEmailAndPassword,
	registerWithEmailAndPassword,
	sendPasswordReset,
	logout,
};
