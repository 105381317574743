import React from "react";

import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import Loader from "components/Loader";
import Header from "components/Header";
import Hero2 from "sections/Hero2";
import About from "sections/About";
import Features from "sections/Features";
import Gallery from "sections/Gallery";
import Pricing from "sections/Pricing";
import Testimonial from "sections/Testimonial";
import Partner from "sections/Partner";
import Contact from "sections/Contact";
import Newsletter from "sections/Newsletter";
import Footer from "components/Footer";

const Home = (props) => {
	return (
		<div className="main-page-wrapper">
			<Loader />

			<Header title={props.title} />

			<ScrollAnimation animateIn="fadeIn">
				<Hero2 />
			</ScrollAnimation>

			<ScrollAnimation animateIn="fadeIn">
				<About />
			</ScrollAnimation>

			<ScrollAnimation animateIn="fadeIn">
				<Features />
			</ScrollAnimation>

			<ScrollAnimation animateIn="fadeIn">
				<Gallery />
			</ScrollAnimation>

			{/* <ScrollAnimation animateIn="fadeIn">
				<Pricing />
			</ScrollAnimation> */}

			{/* <ScrollAnimation animateIn="fadeIn">
				<Testimonial />
			</ScrollAnimation> */}

			<ScrollAnimation animateIn="fadeIn">
				<Partner />
			</ScrollAnimation>

			<ScrollAnimation animateIn="fadeIn">
				<Contact />
			</ScrollAnimation>

			<ScrollAnimation animateIn="fadeIn">
				<Newsletter />
			</ScrollAnimation>

			<ScrollAnimation animateIn="fadeIn">
				<Footer />
			</ScrollAnimation>
		</div>
	);
};

export default Home;
