const signIn = {
	pageTitle: "Login",
	description: "Login",
	section: "Login",
	linkName: "Login",
	route: "/accedi",
};

const dashboard = {
	pageTitle: "CMS - Dashboard",
	description: "Dashboard",
	section: "Dashboard",
	linkName: "Dashboard",
	route: "/cms-dashboard",
};

export { signIn, dashboard };
