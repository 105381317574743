import Header from "components/Header";

const Service = () => {
	return (
		<>
			<Header />
			<div>Servizio</div>
		</>
	);
};

export default Service;
