import React, { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

import { contacts } from "frontend-structure";
import { routes } from "routes";

let location;

const Header = ({ title }) => {
	location = useLocation();

	useEffect(() => {
		document.title = title;
	}, [title]);

	const renderRoutes = () =>
		routes.map((route) => {
			// se è una route con figli
			if (route.visible) {
				if ("collapse" in route) {
					//controllo se è un menù a discesa standard
					if (route.collapseType === "standard") {
						return (
							<>
								<li
									key={route.key}
									className={`nav-item dropdown ${
										location.pathname === route.route ? "active" : ""
									}`}>
									<Link
										className="nav-link dropdown-toggle"
										to={route.route}
										data-toggle="dropdown">
										{route.linkName}.
									</Link>
									<ul className="dropdown-menu">
										{route.collapse.map((section) => {
											return (
												<li key={route.key + "-sub"} className="dropdown-submenu dropdown">
													<Link
														className="dropdown-item dropdown-toggle"
														to={section.route}
														data-toggle="dropdown">
														{section.section}
													</Link>
													<ul className="dropdown-menu">
														{section.collapse.map((subsection) => {
															return (
																<li>
																	<Link to={subsection.route} className="dropdown-item">
																		{subsection.linkName}
																	</Link>
																</li>
															);
														})}
													</ul>
												</li>
											);
										})}
									</ul>
								</li>
							</>
						);
					}
					//o se è un megamenù
					else if (route.collapseType === "megamenu") {
						return (
							<>
								<li
									className={`nav-item dropdown mega-dropdown-holder ${
										location.pathname === route.route
									}`}>
									<Link
										className="nav-link dropdown-toggle"
										to={route.route}
										data-toggle="dropdown">
										{route.linkName}.
									</Link>
									<ul className="dropdown-menu mega-container">
										<li>
											<div className="clearfix">
												<div className="row">
													{route.collapse.map((section) => {
														return (
															<div className="col-lg-2 col-md-4 col-sm-6 mt-3 mb-3 mt-lg-3 mb-lg-3">
																<a href="index.html" className="img-box">
																	<span className="img">
																		<img src="images/menu-img/home-1.jpg" alt="" />
																	</span>
																	<span className="text">{section.linkName}</span>
																</a>
															</div>
														);
													})}
												</div>
											</div>
										</li>
									</ul>
								</li>
							</>
						);
					}
				} else {
					return (
						<>
							<li className={`nav-item ${location.pathname === route.route ? "active" : ""}`}>
								<Link className="nav-link dropdown-toggle" to={route.route}>
									{route.linkName}.
								</Link>
							</li>
						</>
					);
				}

				return null;
			}
		});

	//per home e about restituisco menù normale
	if (
		location.pathname === "/" ||
		location.pathname === "/home" ||
		location.pathname === "/shop" ||
		location.pathname === "/about" ||
		location.pathname === "/error"
	) {
		return (
			<div className="theme-main-menu theme-menu-two main-p-color sticky-menu">
				<div className="d-flex align-items-center">
					<div className="logo">
						<Link to="/">
							<img src="images/logo/logo.svg" alt="" />
						</Link>
					</div>
					<div className="right-content ml-auto order-lg-3">
						<Link
							to={contacts.route}
							className="theme-btn line-button-one button-rose contact-button button-white-bg">
							{contacts.action}
						</Link>
					</div>
					<nav id="mega-menu-holder" className="navbar navbar-expand-lg order-lg-2">
						<div className="container nav-container">
							<button
								className="navbar-toggler navbar-toggler-right"
								type="button"
								data-toggle="collapse"
								data-target="#navbarSupportedContent"
								aria-controls="navbarSupportedContent"
								aria-expanded="false"
								aria-label="Toggle navigation">
								<i className="flaticon-setup"></i>
							</button>
							<div className="collapse navbar-collapse" id="navbarSupportedContent">
								<ul className="navbar-nav">{renderRoutes()}</ul>
							</div>
						</div>
					</nav>
				</div>
			</div>
		);

		//menù a destra
	} else if (
		location.pathname === "/servizi" ||
		location.pathname === "/contatti" ||
		location.pathname === "/portfolio"
	) {
		return (
			<>
				<div className="theme-main-menu theme-menu-one main-p-color sticky-menu">
					<div className="d-flex align-items-center">
						<div className="logo mr-auto">
							<Link to="/">
								<img src="images/logo/logo.svg" alt="" />
							</Link>
						</div>
						<div className="right-content order-lg-3">
							<ul className="d-flex align-items-center">
								<li className="action-list-item">
									<Link
										to={contacts.route}
										className="theme-btn line-button-one button-rose contact-button button-white-bg">
										{contacts.action}
									</Link>
								</li>
							</ul>
						</div>
						<nav id="mega-menu-holder" className="navbar navbar-expand-lg order-lg-2">
							<div className="container nav-container">
								<button
									className="navbar-toggler navbar-toggler-right"
									type="button"
									data-toggle="collapse"
									data-target="#navbarSupportedContent"
									aria-controls="navbarSupportedContent"
									aria-expanded="false"
									aria-label="Toggle navigation">
									<i className="flaticon-setup"></i>
								</button>
								<div className="collapse navbar-collapse" id="navbarSupportedContent">
									<ul className="navbar-nav">{renderRoutes()}</ul>
								</div>
							</div>
						</nav>
					</div>
				</div>
			</>
		);

		//menù nero allo scroll
	} else if (location.pathname === "/nessunovuolequestomenu") {
		return (
			<>
				<div className="theme-main-menu theme-menu-one main-p-color menu-text-white sticky-menu">
					<div className="d-flex align-items-center">
						<div className="logo mr-auto">
							<Link to="/">
								<img src="images/logo/logo.svg" alt="" />
							</Link>
						</div>
						<div className="right-content order-lg-3">
							<ul className="d-flex align-items-center">
								<li className="action-list-item">
									<Link
										to={contacts.route}
										className="theme-btn line-button-one button-rose contact-button button-white-bg">
										{contacts.action}
									</Link>
								</li>
							</ul>
						</div>
						<nav id="mega-menu-holder" className="navbar navbar-expand-lg order-lg-2">
							<div className="container nav-container">
								<button
									className="navbar-toggler navbar-toggler-right"
									type="button"
									data-toggle="collapse"
									data-target="#navbarSupportedContent"
									aria-controls="navbarSupportedContent"
									aria-expanded="false"
									aria-label="Toggle navigation">
									<i className="flaticon-setup"></i>
								</button>
								<div className="collapse navbar-collapse" id="navbarSupportedContent">
									<ul className="navbar-nav">{renderRoutes()}</ul>
								</div>
							</div>
						</nav>
					</div>
				</div>
			</>
		);
	}
};

export default Header;
