const hero = {
	name: "Paolo Bassini",
	row1: "Designer, developer",
	row2: "& Copywriter",
	text: "Tutto quello che serve nel web, al tuo servizio.",
};

const home = {
	pageTitle: "Home",
	description: "Home",
	section: "Home",
	linkName: "Home",
	route: "/home",
};

const about = {
	pageTitle: "About",
	hero: "Designer",
	hero2: "Developer",
	description: "Tutto quello che serve nel web, al tuo servizio.",
	section: "About",
	linkName: "About",
	route: "/about",
};

const categories = [
	{
		name: "Tutti i progetti",
	},
	{
		name: "Web design",
	},
	{
		name: "UX design",
	},
	{
		name: "Applicazioni full stack",
	},
	{
		name: "SEO & Copywriting",
	},
];

const servicesPage = {
	pageTitle: "Servizi",
	description: "Servizi",
	section: "Servizi",
	linkName: "Servizi",
	route: "/servizi",
};

const services = [
	{
		pageTitle: "Web design",
		category: categories[1].name,
		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		section: "Servizi",
		linkName: "Web design",
		route: "/servizi/web-design",
	},
	{
		pageTitle: "UX / UI design",
		category: categories[2].name,
		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		section: "Servizi",
		linkName: "UX designer",
		route: "/servizi/ux-design",
	},
	{
		pageTitle: "Full stack development",
		category: categories[3].name,
		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		section: "Servizi",
		linkName: "Full stack developer",
		route: "/servizi/full-stack-development",
	},
	{
		pageTitle: "Copywriting",
		category: categories[4].name,
		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		section: "Servizi",
		linkName: "Copywriter",
		route: "/servizi/Copywriting",
	},
];

const portfolioPage = {
	pageTitle: "Portfolio",
	description: "Portfolio",
	section: "Portfolio",
	linkName: "Portfolio",
	route: "/portfolio",
};

const portfolio = [
	{
		pageTitle: "Gagliardi costruzioni",
		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		section: "Portfolio",
		linkName: "Gagliardi costruzioni",
		route: "/portfolio/gagliardi-costruzioni",
	},
	{
		pageTitle: "AB Case Milano",
		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		section: "Portfolio",
		linkName: "AB Case Milano",
		route: "/portfolio/ab-case-milano",
	},
	{
		pageTitle: "Alessandro Zibbra",
		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		section: "Portfolio",
		linkName: "Alessandro Zibbra",
		route: "/portfolio/alessandro-zibbra",
	},
	{
		pageTitle: "Paolo Bassini",
		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		section: "Portfolio",
		linkName: "Paolo Bassini",
		route: "/portfolio/paolo-bassini",
	},
];

const blog = {
	pageTitle: "Blog",
	description: "Blog",
	section: "Blog",
	linkName: "Blog",
	route: "/blog",
};

const contacts = {
	pageTitle: "Contatti",
	description: "",
	section: "Contatti",
	linkName: "Contatti",
	action: "Contattami",
	route: "/contatti",
	address: "Via Trieste 44, 26841, Casalpusterlengo (LO)",
	shortAddress: "Via Trieste 44, 26841",
	mail: "info@paolobassini.it",
	phone: "393 151 2786",
	instagram: "https://www.instagram.it",
	facebook: "",
	linkedin: "",
	twitter: "",
};

const shop = {
	pageTitle: "Shop",
	description: "Shop",
	section: "Shop",
	linkName: "Shop",
	route: "/shop",
};

const error = {
	pageTitle: "Errore",
	title: "Pagina non trovata",
	subtitle: "Sono spiacente, non ho trovato il contenuto che cercavi",
	route: "/error",
};

export {
	hero,
	home,
	about,
	servicesPage,
	services,
	portfolioPage,
	portfolio,
	blog,
	contacts,
	shop,
	error,
};
