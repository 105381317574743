import Home from "pages/Home";
import About from "pages/About";
import Services from "pages/Services";
import Service from "pages/Service";
import Portfolio from "pages/Portfolio";
import Project from "pages/Project";
import Blog from "pages/Blog";
import Contact from "pages/Contact";
import Shop from "pages/Shop";
import Error from "pages/Error";

import SignIn from "pages/SignIn";
import Dashboard from "pages/cms/Dashboard";

import {
	home,
	about,
	categories,
	servicesPage,
	services,
	portfolioPage,
	portfolio,
	blog,
	contacts,
	shop,
	error,
} from "frontend-structure";

import { signIn, dashboard } from "cms-structure";

const appname = process.env.REACT_APP_WEBSITE_NAME;

const routes = [
	{
		key: "home",
		...home,
		component: <Home title={`${home.pageTitle} - ${appname}`} />,
		visible: true,
	},
	{
		key: "about",
		...about,
		component: <About title={`${about.pageTitle} - ${appname}`} />,
		visible: true,
	},
	{
		key: "services",
		...servicesPage,
		component: <Services title={`${servicesPage.pageTitle} - ${appname}`} />,
		collapseType: "standard",
		collapse: [
			{
				key: "designer",
				section: "Design",
				collapse: [
					{
						key: "service-0",
						...services[0],
						component: <Service title={`${services[0].pageTitle} - ${appname}`} />,
					},
					{
						key: "service-1",
						...services[1],
						component: <Service title={`${services[1].pageTitle} - ${appname}`} />,
					},
				],
			},
			{
				key: "developer",
				section: "Sviluppo",
				collapse: [
					{
						key: "service-2",
						...services[2],
						component: <Service title={`${services[2].pageTitle} - ${appname}`} />,
					},
				],
			},
			,
			{
				key: "copywriter",
				section: "Copywrite",
				collapse: [
					{
						key: "service-3",
						...services[3],
						component: <Service title={`${services[2].pageTitle} - ${appname}`} />,
					},
				],
			},
		],
		visible: true,
	},
	{
		key: "portfolio",
		...portfolioPage,
		component: <Portfolio title={`${portfolioPage.pageTitle} - ${appname}`} />,
		collapseType: "megamenu",
		collapse: [
			{
				key: "project-0",
				...portfolio[0],
				component: <Project title={`${portfolio[0].pageTitle} - ${appname}`} />,
			},
			{
				key: "project-1",
				...portfolio[1],
				component: <Project title={`${portfolio[1].pageTitle} - ${appname}`} />,
			},
			{
				key: "project-2",
				...portfolio[2],
				component: <Project title={`${portfolio[2].pageTitle} - ${appname}`} />,
			},
			{
				key: "project-3",
				...portfolio[3],
				component: <Project title={`${portfolio[3].pageTitle} - ${appname}`} />,
			},
		],
		visible: true,
	},
	{
		key: "blog",
		...blog,
		component: <Blog title={`${blog.pageTitle} - ${appname}`} />,
		visible: false,
	},
	{
		key: "contatti",
		...contacts,
		component: <Contact title={`${contacts.pageTitle} - ${appname}`} />,
		visible: false,
	},
	{
		key: "error",
		...error,
		component: <Error title={`${error.pageTitle} - ${appname}`} />,
		visible: false,
	},
	{
		key: "signin",
		...signIn,
		component: <SignIn title={`${signIn.pageTitle} - ${appname}`} />,
		visible: true,
	},
	{
		key: "cms-dashboard",
		...dashboard,
		component: <Dashboard title={`${dashboard.pageTitle} - ${appname}`} />,
		visible: false,
	},
];

export { routes };
