import React, { useEffect } from "react";

// routing
import { useNavigate, useLocation } from "react-router-dom";

// form hook
import { useForm } from "react-hook-form";

import { swalToast } from "../../utilities";

// firebase
import { auth, logInWithEmailAndPassword } from "firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";

const SignIn = () => {
	const [user, loading] = useAuthState(auth);
	const navigate = useNavigate();
	const location = useLocation();

	const { handleSubmit, register } = useForm({
		defaultValues: {
			email: "",
			password: "",
		},
	});

	useEffect(() => {
		if (loading) {
			// maybe trigger a loading screen
			return;
		}
	}, [user, loading]);

	const onSubmit = async (form) => {
		console.log(form.email, form.password);

		const response = await logInWithEmailAndPassword(form.email, form.password);

		console.log(response);

		if (response !== true) {
			swalToast({
				icon: "error",
				title: `Attenzione errore!`,
				text: `${await response}`,
				position: "top",
				showConfirmButton: false,
				timer: 5000,
			});
		} else {
			handleRedirect();
		}
	};

	const handleRedirect = () => {
		location.state && location.state.pathname
			? navigate(location.state.pathname)
			: navigate(`/cms-dashboard`);
	};

	return (
		<>
			<div className="signUp-page signUp-standard pt-250 pb-100 md-pt-200">
				<div className="shape-wrapper">
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
				</div>
				<div className="signUp-illustration">
					<img src="images/home/sign-up.svg" alt="" />
				</div>
				<div className="container">
					<div className="row">
						<div className="col-lg-7 ml-auto">
							<div className="signin-form-wrapper">
								<div className="title-area">
									<h3>Login.</h3>
								</div>
								<form method="post" autoComplete="off" id="login-form">
									<input type="hidden" autoComplete="false" />
									<div className="row">
										<div className="col-12">
											<div className="input-group">
												<input type="email" id="email-text-input" {...register("email")} required />
												<label>Email</label>
											</div>
										</div>
										<div className="col-12">
											<div className="input-group">
												<input
													type="password"
													id="email-text-input"
													{...register("password")}
													required
												/>
												<label>Password</label>
											</div>
										</div>
									</div>
									<div className="agreement-checkbox d-flex justify-content-between align-items-center">
										<div>
											<input type="checkbox" id="remember" />
											<label htmlFor="remember">Remember Me</label>
										</div>
										<a href="#">Forget Password?</a>
									</div>
									<button
										className="theme-btn solid-button-one button-rose radius3"
										onClick={handleSubmit(onSubmit)}>
										Login
									</button>
								</form>
								{/* <p className="signUp-text">
									Don’t have any account? <a href="sign-up-standard.html">Sign up</a> now.
								</p> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SignIn;
