import Header from "components/Header";
import Loader from "components/Loader";
import Footer from "components/Footer";

const Services = () => {
	return (
		<>
			<div className="main-page-wrapper">
				<Loader />

				<Header />

				<div className="text-inner-banner-one pos-r">
					<div className="shape-wrapper">
						<svg className="img-shape shape-one">
							<path
								fillRule="evenodd"
								fill="rgb(255, 223, 204)"
								d="M6.000,12.000 C9.314,12.000 12.000,9.314 12.000,6.000 C12.000,2.686 9.314,-0.000 6.000,-0.000 C2.686,-0.000 -0.000,2.686 -0.000,6.000 C-0.000,9.314 2.686,12.000 6.000,12.000 Z"></path>
						</svg>
						<svg className="img-shape shape-two">
							<path
								fillRule="evenodd"
								fill="rgb(182, 255, 234)"
								d="M6.000,12.000 C9.314,12.000 12.000,9.314 12.000,6.000 C12.000,2.686 9.314,-0.000 6.000,-0.000 C2.686,-0.000 -0.000,2.686 -0.000,6.000 C-0.000,9.314 2.686,12.000 6.000,12.000 Z"></path>
						</svg>
						<svg className="img-shape shape-three">
							<path
								fillRule="evenodd"
								fill="rgb(181, 198, 255)"
								d="M12.000,24.000 C18.627,24.000 24.000,18.627 24.000,12.000 C24.000,5.372 18.627,-0.000 12.000,-0.000 C5.372,-0.000 -0.000,5.372 -0.000,12.000 C-0.000,18.627 5.372,24.000 12.000,24.000 Z"></path>
						</svg>
						<svg className="img-shape shape-four">
							<path
								fillRule="evenodd"
								fill="rgb(255, 156, 161)"
								d="M7.500,15.000 C11.642,15.000 15.000,11.642 15.000,7.500 C15.000,3.358 11.642,-0.000 7.500,-0.000 C3.358,-0.000 -0.000,3.358 -0.000,7.500 C-0.000,11.642 3.358,15.000 7.500,15.000 Z"></path>
						</svg>
						<svg className="img-shape shape-five">
							<path
								fillRule="evenodd"
								fill="rgb(178, 236, 255)"
								d="M12.500,25.000 C19.403,25.000 25.000,19.403 25.000,12.500 C25.000,5.596 19.403,-0.000 12.500,-0.000 C5.596,-0.000 -0.000,5.596 -0.000,12.500 C-0.000,19.403 5.596,25.000 12.500,25.000 Z"></path>
						</svg>
					</div>
					<div className="container">
						<p>Services</p>
						<h2 className="pt-30 pb-15">Provided Services</h2>
						<p className="sub-heading">
							From a team of passionate creators working side-by-side with our partners to
							deliver engaging digital and physical campaigns.
						</p>
					</div>
				</div>

				<div className="our-service md-pb-20">
					<div
						className="service-modern-block pt-100 pb-110"
						sx="background-color: #fef8e2;">
						<div className="container">
							<div className="wrapper">
								<div className="row align-items-center">
									<div className="col-md-7 order-lg-last">
										<div className="num">01</div>
										<h2 className="title">
											<a>Mobile Application Design &amp; Dev.</a>
										</h2>
										<a className="more">
											<i className="flaticon-next"></i>
										</a>
									</div>
									<div className="col-md-5 order-lg-first">
										<div className="icon sm-mt-50">
											<img src="images/icon/icon14.svg" alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						className="service-modern-block pt-100 pb-110"
						sx="background-color: #defcee;">
						<div className="container">
							<div className="wrapper">
								<div className="row align-items-center">
									<div className="col-md-7">
										<div className="num">02</div>
										<h2 className="title">
											<a>Interface Designer &amp; UX Specialist.</a>
										</h2>
										<a className="more">
											<i className="flaticon-next"></i>
										</a>
									</div>
									<div className="col-md-5">
										<div className="icon sm-mt-50">
											<img src="images/icon/icon15.svg" alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						className="service-modern-block pt-100 pb-110"
						sx="background-color: #ffe8e6;">
						<div className="container">
							<div className="wrapper">
								<div className="row align-items-center">
									<div className="col-md-7 order-lg-last">
										<div className="num">03</div>
										<h2 className="title">
											<a>Social &amp; Online Marketing.</a>
										</h2>
										<a className="more">
											<i className="flaticon-next"></i>
										</a>
									</div>
									<div className="col-md-5 order-lg-first">
										<div className="icon sm-mt-50">
											<img src="images/icon/icon16.svg" alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						className="service-modern-block pt-100 pb-110"
						sx="background-color: #e6edff;">
						<div className="container">
							<div className="wrapper">
								<div className="row align-items-center">
									<div className="col-md-7">
										<div className="num">04</div>
										<h2 className="title">
											<a>Article &amp; Keyword Research.</a>
										</h2>
										<a className="more">
											<i className="flaticon-next"></i>
										</a>
									</div>
									<div className="col-md-5">
										<div className="icon sm-mt-50">
											<img src="images/icon/icon17.svg" alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Footer />
			</div>
		</>
	);
};

export default Services;
